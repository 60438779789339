@use 'sass:map';

$sizes: (
	0: 0,
	auto: auto,
	xxs: .125rem,
	xs: .25rem,
	sm: .5rem,
	md: 1rem,
	lg: 2rem,
	xl: 4rem,
);

@function size($key) {
	@return map.get($sizes, $key)
};

@mixin generateSpacingUtilityClasses($class-prefix, $space-type) {
	@each $k, $v in $sizes {
		.#{$class-prefix}-#{$k} {
			#{$space-type}: $v !important;
		}

		.#{$class-prefix}-l-#{$k} {
			#{$space-type}-left: $v !important;
		}

		.#{$class-prefix}-r-#{$k} {
			#{$space-type}-right: $v !important;
		}

		.#{$class-prefix}-t-#{$k} {
			#{$space-type}-top: $v !important;
		}

		.#{$class-prefix}-b-#{$k} {
			#{$space-type}-bottom: $v !important;
		}

		.#{$class-prefix}-h-#{$k} {
			#{$space-type}-left: $v !important;
			#{$space-type}-right: $v !important;
		}

		.#{$class-prefix}-v-#{$k} {
			#{$space-type}-top: $v !important;
			#{$space-type}-bottom: $v !important;
		}
	}
}

@include generateSpacingUtilityClasses('pad', 'padding');
@include generateSpacingUtilityClasses('mrg', 'margin');
@include generateSpacingUtilityClasses('gap', 'gap');

.width-100 {
	width: 100%;
}