@use '../../../styles/space';

.map-info-popup {
	display: flex;
	flex-direction: column;

	> * + * {
		margin-block-start: space.size(sm);
	}

	padding: space.size(xs);
	
	* {
		color: black;
		font-size: 1rem;
	}

	h2 {
		padding: 0;
		margin: 0;
		margin-block-end: spa;
		font-size: 1.2rem;
	}

	hr {
		border-width: 0;
		border-block-start-width: 1px;
	}
}
