@use '../../../../../styles/palette';
@use '../../../../../styles/space';


.device-snmp-value {
	display: flex;
	flex-direction: column;

	> * + * {
		margin-block-start: space.size(sm);
	}

	&__name {
		padding: space.size(sm);
	
		&--success {
			padding: 0;
		}
	}

	pre {
		margin: 0;
	}
}