@use '../styles/palette';

$snmpStatusColors: (
	'SUCCESS': palette.$text-good,
	'WAITING': palette.$text,
	'PARTIAL': palette.$text-warning,
	'FAILURE': palette.$text-danger,
	'UNIDENTIFIED': palette.$text,
	'NOT_IDENTIFIED': palette.$text,
	'NO_CONNECTION_DETAILS': palette.$text-danger,
);

@each $status, $color in $snmpStatusColors {
	.snmpStatus--#{$status} {
		color: $color;
	}
}

