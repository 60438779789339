@use '../../../../../styles/space';
@use '../../../../../styles/breakpoint';

.inventory-filter-view {
	display: flex;
	flex-direction: column;
	gap: space.size(md);

	@include breakpoint.up(md) {
		display: grid;
		grid-template-areas:
			'kinds query'
			'deviceTypes deviceTypes'
		;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		row-gap: 0;
	}

	@include breakpoint.down(md) {
		.button-group {
			> .btn {
				flex: 1;
				white-space: nowrap;
			}
		}	
	}

	&__query {
		grid-area: query;
	}

	&__kinds {
		grid-area: kinds;
	}

	&__kindSpecificFilters {
		grid-area: deviceTypes;
		margin-block-start: space.size(md);
	}

	select {
		font-size: 1.2rem;
	}
}