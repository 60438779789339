.repository-link {
	.powercode-icon {
		filter: grayscale(75%);
		opacity: .8;
		margin-right: -.2em;
		transform: translateY(.1em);
	}

	i {
		height: 1em;
		font-size: 1em;
	}

	&--with-children {
		.powercode-icon {
			margin-left: .2em;
		}
	}
	
	&:hover {
		.powercode-icon {
			filter: none;
			opacity: 1;
		}
	}
}