@use '../../styles/space';
@use '../../styles/shadow';

.betaFlag {
	padding: 2px space.size(sm);
	background: var(--color-brand);
	color: var(--color-text-hl);
	font-size: .9rem;
	font-weight: bold;
	font-family: monospace;
	transform: skewX(-15deg);
	box-shadow: shadow.$shadow-xs;
	margin-inline: space.size(sm);
}