@use '../../../../styles/space';
@use '../../../../styles/breakpoint';


.inventory-item-list-view {
	display: grid;

	--inventory-item-list-columns: 1;
	
	grid-template-columns: repeat(var(--inventory-item-list-columns), 1fr);
	gap: space.size(md);

	@include breakpoint.up(sm) {
		--inventory-item-list-columns: 2;
	}

	@include breakpoint.up(md) {
		--inventory-item-list-columns: 3;
	}
	 
	@include breakpoint.up(lg) {
		--inventory-item-list-columns: 4;
	}

	@include breakpoint.up(xl) {
		--inventory-item-list-columns: 5;
	}

	@include breakpoint.up(xl) {
		--inventory-item-list-columns: 6;
	}

	margin: 0;
	padding: 0;
}