@use '../../styles/space';

.customer-alternative-devices-filter-view {
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	&, .section {
		> * + * {
			margin-block-start: space.size(xs);
		}
	}

	.section {
		padding-inline-start: 10px;
	}
	
	.band-checkbox {
		font-weight: 500;
	}

	summary {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.customer-alternative-devices-filter-view-container & {
		max-height: calc(100vh - (450px));
		padding-inline-end: 8px;
	}
}

.customer-alternative-devices-filter-view-container {
	padding-inline-end: 0;
}