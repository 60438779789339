@use 'sass:map';

$breakpoints: (
	sm: 600px,
	md: 900px,
	lg: 1200px,
	xl: 1800px,
	xxl: 2200px,
);

@function breakpoint($key) {
	@return map.get($breakpoints, $key);
}

$next-breakpoint: (
	sm: breakpoint(md) - .02px,
	md: breakpoint(lg) - .02px,
	lg: breakpoint(xl) - .02px,
	xl: breakpoint(xxl) - 0.2px,
);

$sm: 800px;

@mixin up($size) {
	@media (min-width: map.get($breakpoints, $size)) {
		@content;
	}
}

@mixin down($size) {
	@media (max-width: map.get($breakpoints, $size) - 0.02px) {
		@content;
	}
}

@mixin only($size) {
	@if($size == xl) {
		@include up(xl) {
			@content;
		}
	}
	@if($size != xl) {
		@media (min-width: map.get($breakpoints, $size)) and (max-width: map.get($next-breakpoint, $size)) {
			@content;
		}
	}
}

@each $key, $value in $breakpoints {
	.bp-up-#{$key} {
		@include down($key) {
			display: none !important;
		}
	}

	.bp-down-#{$key} {
		@include up($key) {
			display: none !important;
		}
	}
}
