@use '../styles/breakpoint';
@use '../styles/palette';
@use '../styles/space';


.filter-button {
	$breakpoint: sm;

	display: flex;
	align-items: center;

	i {
		width: 2ex;

		&.eye-icon {
			display: none;
			margin-right: space.size(sm);
		}
	}


	&__label--sm {
		margin-left: 0 !important;
	}

	&__label--lg {
		display: none;
	}

	padding: 0 space.size(sm);
	@include breakpoint.up(sm) {
		padding: 0 space.size(sm);
		i.eye-icon {
			display: unset;
		}

		&__label--sm {
			display: none;
		}

		&__label--lg {
			display: unset;
		}
	}

	&[aria-selected="false"] {
		--btn-color-bg: #{palette.$fg};
		--btn-color-bg-hl: #{palette.$hl};
		--btn-color-fg: #{palette.$text};
		--btn-color-fg-hl: #{palette.$text};
		--btn-color-border: #{palette.$text};
	}
}
