@use './space';

@each $size, $space in space.$sizes {
	.stack-#{$size} {
		display: flex;
		flex-direction: column;

		& > * + * {
			margin-top: $space;
		}
	}
}

@each $size, $space in space.$sizes {
	.columns-#{$size} {
		display: flex;

		& > * + * {
			margin-left: $space;
		}
	}
}

.content {
	box-sizing: border-box;
	width: 100%;
	max-width: 90ch;
	margin-left: auto;
	margin-right: auto;

	&--narrow {
		width: 60ch;
	}

	&--veryNarrow {
		width: 30ch;
	}
}
