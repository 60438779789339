@use '../../styles/space';

.csvUploadSchema.panel-group {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: space.size(sm);

	.csvUploadItem {
		grid-column: 1 / -1;
		display: grid;
		column-gap: space.size(sm);
		grid-template-columns: auto 1fr;
		grid-template-columns: subgrid;
	}
}
