@use '../styles/space';
@use '../styles/palette';

.license-alert {
	display: grid;
	grid-template-areas:
		'head close'
		'body body'
	;
	grid-template-columns: 1fr auto;
	row-gap: space.size(sm);
	
	padding: space.size(sm) space.size(xs) space.size(sm) space.size(md);
	border-bottom: 2px solid palette.$hl;
	background-color: var(--license-alert-bg-color, palette.$bg);
	
	&__head {
		display: flex;
		align-items: center;

		grid-area: head;
		margin: 0;

		color: var(--license-alert-fg-color, palette.$text-hl);
		
		> * + * {
			margin-left: space.size(sm);
		}
	}

	&--info {
		--license-alert-fg-color: #{palette.$brand};
	}
	&--warning {
		--license-alert-bg-color: #{palette.$hl-warning};
	}
	&--error {
		--license-alert-bg-color: #{palette.$hl-danger};
	}
	
	&__head {
		grid-area: head;
	}

	&__body {
		grid-area: body;

		p {
			margin: 0;

			& + p {
				margin-top: space.size(xs);
			}
		}
	}

	&__close {
		grid-area: close;
	}
}