@use '../../../../styles/space';
@use '../../../../styles/palette';

.inventory-result-view {
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&, &:hover, &:hover * {
		text-decoration-color: transparent;
	}

	&:hover, &:focus {
		background-color: palette.$hl;

		&, * {
			text-decoration: underline transparent;
		}
	}

	&__header {
		display: grid;
		grid-template-areas:
			'icon name'
			'icon description'
		;
		grid-template-columns: auto 1fr;
		grid-template-rows: 1fr auto;
		column-gap: space.size(sm);

		&-icon {
			grid-area: icon;
			margin: auto;
			margin-block-start: 0;
		}

		&-name {
			grid-area: name;
			margin: auto 0;
		}

		&-description {
			grid-area: description;
			text-decoration: none !important;
		}
	}

	> * + * {
		margin-block-start: space.size(sm);

		&, * {
			text-decoration: none !important;
		}
	}

	&__matched-field-list {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-block-start: space.size(sm);
	}
}