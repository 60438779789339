@use '../../styles/space';

.page-layout {
	--page-header-height: 3.5rem;
	--page-footer-height: 1.75rem;
	--page-main-height: calc(100vh - var(--page-header-height) - var(--page-footer-height));
	
	display: grid;
	grid-template-areas:
		'header'
		'main'
		'footer'
	;
	grid-template-rows: var(--page-header-height) minmax(var(--page-main-height), auto) var(--page-footer-height);

	min-height: 100vh;

	& > header {
		grid-area: header;
	}

	& > main {
		grid-area: main;
		display: flex;
		flex-direction: column;
	}

	& > footer {
		grid-area: footer;
	}
}