@use './space';

.round {
	border-radius: 50%;
}

$border-radius: space.size(xs) + space.size(xxs);

.rounded {
	border-radius: $border-radius;
}
