@use '../../../../styles/breakpoint';
@use '../../../../styles/palette';
@use '../../../../styles/space';
@use '../../../../styles/shape';


.equipment-row {
	$rowWidth: 400px;
	position: relative;
	width: $rowWidth;
	max-width: $rowWidth;

	display: grid;
	grid-template-areas: 
		'name         name'
		'azimuth      frequency'
		'sibling-name sibling-data'
	;
	grid-template-columns: 1fr 1fr;
	gap: space.size(xs);

	@include breakpoint.up(sm) {
		$azimuthWidth: 10ch;
		$frequencyWidth: 14ch;
		grid-template-areas:
			'name         azimuth frequency'
			'sibling-name .       sibling-data'
		;
		grid-template-columns: calc(400px - $azimuthWidth - $frequencyWidth - space.size(xs)) $azimuthWidth $frequencyWidth;
	}

	&:hover {
		border-bottom-right-radius: 0;
	}

	&__name, &__sibling-name {
		overflow-wrap: anywhere;
	}

	&__name {
		grid-area: name;
	}

	&__azimuth {
		grid-area: azimuth;
	}

	&__frequency {
		grid-area: frequency;
	}

	&__sibling-name {
		grid-area: sibling-name;
	}

	&__sibling-data {
		grid-area: sibling-data;
	}
	
	&__popup {
		$border-size: 4px;

		position: absolute;
		bottom: -2px;
		right: -6px;
		transform: translateY(100%);

		padding: space.size(xs);
		border-radius: 0 0 shape.$border-radius shape.$border-radius;

		background-color: var(--data-row-bg-hover);
		border: $border-size solid palette.$page;
		border-top: none;
		z-index: 10;

		& > * + * {
			margin-left: space.size(md);
		}
	}
}
