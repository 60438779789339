@use '../../styles/space';
@use '../../styles/breakpoint';


.admin-route {
	--admin-pad-y: #{space.size(md)};
	
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	grid-template-areas: 'controls' 'content';
	margin: 0 auto;
	height: 100%;
	max-height: 100%;
	overflow-y: hidden;

	@include breakpoint.up(sm) {
		--admin-pad-y: #{space.size(lg)};

		grid-template-areas: 'controls content';
		grid-template-columns: minmax(200px, auto) 1fr;
		grid-template-rows: 1fr;

		&__content-wrapper {	
			position: relative;
			max-width: 95vw;
			height: 100%;
		}

		&__content {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: auto;
			padding: var(--admin-pad-y) 0;
		}
	}
	
	width: 100ch;
	max-width: 95vw;
	
	gap: space.size(md);

	&__controls {
		grid-area: controls;
		padding-top: var(--admin-pad-y);
	}

	&__content-wrapper {
		grid-area: content;
	}
}