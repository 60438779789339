@use 'breakpoint';

.site h3 {
    font-size: 1.5em;
    text-align: center;
}

.controls {
    display: flex;
    gap: 10px;
    padding: 0 10px;
    font-size: 1.5em;

    select, option {
        font-size: 1em;
        margin: 10px 0;
        width: 100%;
    }
}

.spectrum-gradient {
    height: 100%;
}


.eq-filter--ap {
    .data-table .eqType--bh {
        display: none; 
    }
    .spectrum .eqType--bh .spectrum-gradient {
        opacity: .3;
    }
    svg .eqType--bh {
        visibility: hidden; 
    }
}
.eq-filter--bh {
    .data-table .eqType--ap {
        display: none; 
    }
    .spectrum .eqType--ap .spectrum-gradient {
        opacity: .3;
    }
    svg .eqType--ap {
        visibility: hidden; 
    }
}

#siteMap {
    font-size: 1.5em;
    padding: 5px 10px;
}

@media (max-width: breakpoint.$sm) {
    .controls {
        flex-direction: column;
    }
}
