@use '../../../styles/palette';
@use '../../../styles/space';


.nav-item {
	display: flex;
	align-items: center;

	height: 100%;
	padding: 0 space.size(md);
	
	font-size: 1.1rem;
	font-weight: bold;

	text-decoration: none;

	&--active {
		&, &:hover {
			color: palette.$brand-hl;
		}
	}
}
