@use '../../styles/palette';
@use '../../styles/space';
@use '../../styles/shadow';
@use '../../styles/shape';

.menu-handler {
	position: relative;
	display: grid;
}

.menu {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateY(100%);
	min-width: 100%;

	z-index: 1000;
	
	&__items {
		width: max-content;
		display: flex;
		flex-direction: column;
		gap: 1px;

		min-width: 125px;

		border-radius: 0 0 shape.$border-radius shape.$border-radius;
		
		background-color: palette.$text;
		color: palette.$bg;
	}
	
	&__item {
		border: 2px solid transparent;
		outline-offset: space.size(xxs);
		outline-width: 2;

		padding: space.size(sm);
		
		background-color: inherit;
		color: palette.$bg;

		font-family: inherit;
		font-size: inherit;
		text-align: start;

		cursor: pointer;
		outline: none;
		
		&:focus, &:hover {
			background-color: palette.$text-hl;
			color: unset;
		}

		&:focus-visible {
			border-color: palette.$brand;
		}

		&:last-child {
			border-radius: 0 0 shape.$border-radius shape.$border-radius;
		}
	}
}
