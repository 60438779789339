@use '../../styles/space';
@use '../../styles/palette';


.checkbox {
	display: grid;
	grid-template-areas:
		'checkbox label'
	;
	grid-template-columns: auto 1fr;
	gap: space.size(xs) space.size(sm);

	input {
		grid-area: checkbox;
		accent-color: palette.$text;
		transform: scale(1.25);
	}

	label {
		grid-area: label;
	}
}
