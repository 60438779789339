@use '../styles/space';
@use '../styles/palette';


.badge {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: space.size(xs) space.size(sm);
	border-radius: .8rem;

	font-size: .8rem;
	color: var(--badge-fg, #{palette.$text-hl});
	background-color: var(--badge-bg);

	user-select: none;
	
	&--info {
		--badge-bg: #{palette.$brand};
	}
	&--success {
		--badge-bg: #{palette.$good};
	}
	&--warning {
		--badge-bg: #{palette.$hl-warning};
	}
	&--error {
		--badge-bg: #{palette.$hl-danger};
	}
}