@use '../shape';
@use '../space';
@use '../palette';
@use '../shadow';


@mixin control-base {
	border: 1px solid palette.$bg;
	border-radius: shape.$border-radius;	
	background-color: palette.$text;
	border: none;

	padding: space.size(xs) space.size(sm);
	
	font-size: 1rem;
	outline-offset: space.size(xxs);
	outline-width: 2;
	outline-color: palette.$brand;

	box-shadow: inset shadow.$shadow-xs;

	&:focus {
		background-color: palette.$text-hl;
		box-shadow: none;
	}
}

select, input {
	@include control-base();

	&::placeholder {
		font-size: .9rem;
	}
}

input[type="range"] {
	box-shadow: none;
	&:focus {
		background-color: unset;
	}
}