@use '../../styles/palette';
@use '../../styles/space';


.more-menu-button {
	position: relative;
	width: 48px;

	cursor: pointer;


	i {
		position: absolute;
		font-size: 24px;
		margin: auto 0;
		top: 50%;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateY(-25%);

		transition: opacity 200ms;
	}

	.fa-times {
		opacity: 0;
	}
	
	&--open {
		.fa-ellipsis-h {
			opacity: 0;
		}

		.fa-times {
			opacity: 1;
		}
	}
}

.more-menu {
	width: 100vw;
	background-color: palette.$bg;
	padding: space.size(md) space.size(sm);

	display: grid;
	grid-template-columns: 1fr;
	gap: space.size(md);
}