@use '../../styles/space';

.link {
	display: inline-flex;
	align-items: center;

	&__startIcon {
		margin-inline-end: space.size(xs);
	}

	&__endIcon {
		margin-inline-start: .1ch;
		opacity: .8;
	}
}