@use '../space';
@use '../shape';
@use '../palette';
@use '../shadow';

.btn {
	--btn-color-bg: #{palette.$text};
	--btn-color-bg-hl: #{palette.$text-hl};
	--btn-color-fg: #{palette.$bg};
	--btn-color-fg-hl: #{palette.$bg};
	--btn-color-border: var(--btn-color-bg);

	box-shadow: shadow.$shadow-sm;

	display: flex;
	justify-content: center;
	align-items: center;

	min-width: space.size(lg);
	min-height: space.size(lg);

	border: 1px solid;
	border-color: var(--btn-color-border, var(--b));
	border-radius: shape.$border-radius;
	padding: 0 1rem;
	border-width: var(--btn-border-width, 1px);

	font-size: 1rem;

	background-color: var(--btn-color-bg);
  color: var(--btn-color-fg);

	cursor: pointer;

	outline-offset: space.size(xxs);
	outline-width: 2;
	
	&:hover:not(&:disabled), &:focus-visible {
		background-color: var(--btn-color-bg-hl);
		color: var(--btn-color-fg-hl);
	}

	&:active {
		transform: translateY(1px);
		box-shadow: none;
		border-color: transparent;
	}

	&:active:disabled {
		transform: none;
	}

	&:disabled {
		opacity: .5;
		cursor: unset;
		box-shadow: none;
	}

	&--icon {
		padding: 0;
	}

	&--icon-only {
		padding: 0 space.size(sm);
		background: none !important;
		border: none !important;
		color: palette.$text !important;

		box-shadow: none;
	}

	& > * + * {
		margin-left: space.size(xs);
	}

	& > i + *, & > * + i, & > .icon + *, & > * + .icon {
		margin-left: space.size(xs);
	}

	svg + * {
		margin-inline-start: space.size(xs);
		margin-block: auto;
	}

	&--invisible {
		padding: 0;
		margin: 0;
		border: none;
		--btn-color-bg: none;
		--btn-color-fg: inherit;
		--btn-color-bg-hl: none;
		--btn-color-fg-hl: inherit;
		box-shadow: none;
	}

	&--primary {
		--btn-color-bg: #{palette.$brand-dark};
		--btn-color-bg-hl: #{palette.$brand};
		--btn-color-fg: white;
		--btn-color-fg-hl: white;
	}

	&--danger {
		--btn-color-bg: #{palette.$fg-danger};
		--btn-color-bg-hl: #{palette.$hl-danger};
		--btn-color-fg: white;
		--btn-color-fg-hl: white;
	}
}

a.btn {
	text-decoration: none !important;
}

.button-group {
	display: flex;
	
	button {
		border-radius: 0;

		&:first-of-type {
			border-start-start-radius: shape.$border-radius;
			border-end-start-radius: shape.$border-radius;
		}

		&:last-of-type {
			border-start-end-radius: shape.$border-radius;
			border-end-end-radius: shape.$border-radius;
		}

		&:not(:last-of-type) {
			border-right: none;
		}
	}

	button + button {
		border-left: 1px solid palette.$fg;
	}
}