:root {
	--color-page: #222222;
	--color-bg: #333333;
	--color-text: #dddddd;
	--color-text-hl: #ffffff;
	--color-text-danger: #F8A9A9;
	--color-text-warning: #c2c235;
	--color-text-good: #14cc24;
	--opacity-fg: 0.2;
	--opacity-hl: 0.5;
	--color-fg: #424242;
	--color-fg-transparent: rgb(127,127,127,var(--opacity-fg));
	--color-fg-danger: #422929;
	--color-fg-warning: #424229;
	--color-hl: #585858;
	--color-hl-transparent: rgb(127,127,127,var(--opacity-hl));
	--color-hl-danger: #581919;
	--color-hl-warning: #585819;
	--color-brand-dark: #0D75A5;
	--color-brand: #12a2e6;
	--color-brand-hl: #2db2ff;
	--color-good: #14cc24;
}

$page: var(--color-page);
$bg: var(--color-bg);
$text: var(--color-text);
$text-hl: var(--color-text-hl);
$text-danger: var(--color-text-danger);
$text-warning: var(--color-text-warning);
$text-good: var(--color-text-good);
$border: var(--color-text);
$opacity-fg: var(--opacity-fg);
$opacity-hl: var(--opacity-hl);
$fg: var(--color-fg);
$fg-danger: var(--color-fg-danger);
$fg-warning: var(--color-fg-warning);
$hl: var(--color-hl);
$hl-transparent: var(--color-hl-transparent);
$hl-danger: var(--color-hl-danger);
$hl-warning: var(--color-hl-warning);
$brand-dark: var(--color-brand-dark);
$brand: var(--color-brand);
$brand-hl: var(--color-brand-hl);
$good: var(--color-good);


.color-good  { color: $good };
.color-brand { color: $brand };
.fg { background-color: $fg; }
.hl { background-color: $hl; }
.color-text-hl { color: $text-hl; }