@use '../styles/palette';
@use '../styles/shape';
@use '../styles/space';


.data-table {
	display: grid;
	grid-auto-flow: columns;
	gap: 3px;

	&__row {
		position: relative;
		border: 2px solid transparent;
		padding: space.size(sm);

		// Reset props when <button>
		color: inherit;
		font-size: inherit;
		text-align: inherit;
		font-family: inherit;

		--data-row-bg: #{palette.$fg};
		--data-row-bg-hover: #{palette.$hl};

		background-color: var(--data-row-bg);
		&:hover, &.highlight {
			background-color: var(--data-row-bg-hover);
		}

		text-decoration: none;
		
		&:first-child {
			border-radius: shape.$border-radius shape.$border-radius 0 0;
		}
		&:last-child {
			border-radius: 0 0 shape.$border-radius shape.$border-radius;
		}
		&:only-child {
			border-radius: shape.$border-radius;
		}
		
		&--warning {
			--data-row-bg: #{palette.$fg-warning};
			--data-row-bg-hover: #{palette.$hl-warning};
		}

		&--danger {
			--data-row-bg: #{palette.$fg-danger};
			--data-row-bg-hover: #{palette.$hl-danger};
		}

		&--selected {
			border-color: palette.$text;
		}

		&--interactive {
			cursor: pointer;
		}
		
		&-content {
			z-index: 1;
		}
	}
}
