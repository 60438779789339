@use '../../../../styles/space';


.choose-provider-view {
	list-style: none;
	padding: 0;

	display: grid;
	grid-auto-flow: row;
	gap: space.size(md);
	place-content: center;

	.provider-button {
		display: grid;
		grid-template-areas:
			'icon label       arrow'
			'icon description arrow'
		;
		grid-template-columns: auto 1fr auto;
		column-gap: space.size(sm);
		padding: space.size(sm);
		width: 100%;

		* {
			text-align: start;
		}

		&__icon {
			grid-area: icon;
		}

		&__label {
			grid-area: label
		}
		
		&__description {
			grid-area: description;
		}

		&__arrow {
			grid-area: arrow;
			margin-inline-start: space.size(sm);
		}
	}
}