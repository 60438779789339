@use '../../../styles/space';


.spectrum-comparison-controls {
	display: grid;
	grid-template-areas:
	'width     reset'
	'slider    slider'
	;
	grid-template-columns: 1fr auto;
	gap: space.size(sm);
	
	code {
		font-weight: bold;
	}
	
	&__slider {
		grid-area: slider;
		display: flex;
		flex-direction: column;

		> * + * {
			margin-top: space.size(xs);
		}
	}
	&__width {
		grid-area: width;
		margin: 0;
	}
	&__reset {
		grid-area: reset;
		margin: auto 0;
	}
}