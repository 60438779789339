@use '../../../styles/palette';
@use '../../../styles/space';
@use '../../../styles/shadow';


.snmp-probe-sql-dialog {
	width: 80vw;
	max-width: 100ch;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	
	h2 {
		margin: 0;
	}

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__sql {
		display: flex;
		overflow: hidden;
		background-color: palette.$text;
		color: palette.$bg;
		padding: space.size(sm);

		overflow-x: auto;

		box-shadow: shadow.$shadow-xs inset;

		pre {
			margin: 0;
		}
	}

	main {
		display: flex;
		flex-direction: column;

		> * + * {
			margin-top: space.size(md);
		}

		h3 {
			display: inline;
			font-size: inherit;
			margin: 0;
		}
	}

	footer {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;
	}
}