@use '../../styles/space';

.modal-loading-indicator {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	opacity: 0;
	transition: opacity var(--modal-transition-duration);

	> * + * {
		margin-top: space.size(md);
	}
	
	&--show {
		opacity: 1;
	}
}