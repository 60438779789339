@use '../../styles/space';
@use '../../styles/palette';
@use '../../styles/typography/typography';

.form {
	display: grid;
	grid-auto-flow: row;
	gap: space.size(md);

	width: 100%;

	h2 {
		margin: 0;
	}
	
	label {
		@include typography.text-size(lg);
		width: 100%;
		display: flex;
		flex-direction: column;

		> * {
			width: 100%;
		}

		> * + * {
			margin-top: space.size(sm);
		}
	}

	input[type=text],
	input[type=password],
	input[type=email],
	input[type=url],
	input[type=search],
	select {
		width: 100%;
		@include typography.text-size(lg);
	}

	input[type="checkbox"] {
		width: unset;
	}

	&__description {
		font-size: .9rem;
		margin-bottom: space.size(xs);

		> * {
			margin: 0;
		}

		> * + * {
			margin-top: .5em;
		}
	}
}