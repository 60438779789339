@use '../../styles/breakpoint';


.map-route {
	display: grid;
	grid-template-rows: auto 1fr;


	height: var(--page-main-height);

	@include breakpoint.up(xl) {
		grid-template-rows: 1fr;
	}

	&__map {
		height: 100%;
	}
}