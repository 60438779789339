@use '../../styles/space';
@use '../../styles/breakpoint';

.sites {
	margin-bottom: space.size(md);
	display: grid;
	place-items: center;
}

.sites-info-grid {
	$columnWidth: 400px;
	display: grid;
	grid-template-columns: repeat(var(--gridColumns, 1), $columnWidth);
	gap: 16px;

	@for $i from 2 through 4 {
		@media (min-width: (($i * $columnWidth) + (($i - 1) * 16px))) {
			--gridColumns: #{$i};
		}
	}

	> * {
		width: 100%;
		max-width: 95vw;
		margin-block-end: auto;
	}

	> .fullWidth {
		grid-column: 1 / -1;
		width: 100%;
	}
}

