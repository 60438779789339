@use 'palette';

:root {
    --spectrum-hover-bs: 0 0 3px #ffffff;
    --spectrum-hover-z: 2;
}

.spectrum {
    border: 2px solid palette.$fg;
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    position: relative;

    > div {
        height: 30px;
        flex-grow: 1;

        &:hover {
            box-shadow: var(--spectrum-hover-bs);
            z-index: var(--spectrum-hover-z);
            
            .spectrum-tooltip {
                display: block;
            }
        }

        &.spectrum-excluded {
            flex-grow: 0;
            background-color: palette.$text;
        }

        &.spectrum-available {
            background-color: palette.$bg;
        }

        &.spectrum-used {
            background-color: rgba(0,0,127,palette.$opacity-hl);
        }

        &.spectrum-conflict {
            background-color: rgba(127,0,0,palette.$opacity-hl);
        }

        .spectrum-tooltip {
            background-color: palette.$bg;
            border: 2px solid palette.$fg;
            border-top: none;
            position: absolute;
            display: none;
            top: 32px;
            left: -2px;
            right: -2px;

            > div {
                margin: 10px;
            }

            span {
                float: right;
            }
        }
    }
}
