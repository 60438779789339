@use '../../styles/components/controls';
@use '../../styles/palette';
@use '../../styles/space';

.text-input {
	@include controls.control-base();
	
	display: grid;
	grid-template-areas: 'icon input';
	grid-template-columns: auto 1fr;
	color: palette.$bg;

	height: 32px;
	
	cursor: text;
	padding: 0;
	
	.icon {
		grid-area: icon;
		margin: auto;
		margin-inline-start: space.size(xs);
	}
	
	input {
		grid-area: input;
		
		border: none;
		border-radius: 0;
		background-color: transparent;
		padding: 0;
		font-size: inherit;
		font-family: inherit;
		box-shadow: none;
		outline: none;
		outline-width: 0;
		outline-color: transparent;
		padding-inline: space.size(xs);

		&:focus {
			background-color: transparent;
		}
	}

	&:focus-within {
		outline-style: solid;
		outline-offset: 1;
		outline-width: 2;
		outline-color: palette.$brand;
		box-shadow: none;
	}
}