@use '../styles/palette';
@use '../styles/space';


.slider {
	display: flex;
	flex-direction: column;

	span {
		padding-bottom: space.size(sm);
	}

	legend > label {
		font-size: 1rem;
	}

	legend {
		padding: 0;
	}
	
	input {
		position: relative;
		-webkit-appearance: none;
		background-color: transparent;

		height: 1rem;
		padding: 0;

		--track-color: #{palette.$text};
		--thumb-color: #{palette.$text-hl};
		
		&::-webkit-slider-runnable-track {
			position: relative;
		}

		&::-moz-range-track {
			background-color: var(--track-color);
		}

		&:hover {
			--thumb-color: #{palette.$text-hl};
		}

		@mixin thumb() {
			background-color: var(--thumb-color);
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			border: none;
			box-shadow: 0 0 5px rgba(black, .5);
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			@include thumb();
		}

		&::-moz-range-thumb {
			@include thumb();
		}

		&::before {
			content: '';
			position: absolute;
			background-color: #{var(--track-color)};
			width: 100%;
			height: 4px;
			left: 0;
			top: calc(50% - 2px);
		}
	}

	&--disabled {
		opacity: .5
	}

	&--slim {
		padding: 0;
		border: none;
	}
}
