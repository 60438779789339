@use '../../../styles/breakpoint';
@use '../../../styles/shadow';
@use '../../../styles/space';
@use '../../../styles/palette';

.page-header-wrapper {
	position: sticky;
	top: 0;
	z-index: 99999;
}

.page-header {
	$small-bp: md;
	$border: 2px solid palette.$hl;
	position: relative;
	
	height: var(--page-header-height);
	
	display: grid;
	grid-template-areas: 'back-button banner toolbar nav';
	grid-template-columns: auto auto 1fr auto;
	
	border-bottom: $border;
	background-color: palette.$bg;
	
	user-select: none;

	z-index: 2;

	&__back-button {
		grid-area: back-button;
		@include breakpoint.up($small-bp) {
			display: none !important;
		}

		padding-right: space.size(md) !important;

		i {
			font-size: 1.5em;
		}
	}
	
	&__banner {
		grid-area: banner;
		display: flex;
		align-items: center;

		a {
			text-decoration: none;
			color: palette.$text;

			&:hover {
				color: unset;
			}
		}
		
		h1.desktop {
			display: none;
			@include breakpoint.up($small-bp) {
				display: unset;
			}

			font-size: 1.6rem;
		}
		
		h1.mobile {
			display: unset;
			@include breakpoint.up($small-bp) {
				display: none;
			}

			font-size: 1.5rem;
		}
	}

	&__toolbar {
		grid-area: toolbar;
		background-color: palette.$page;

		margin-left: space.size(md);
		border-left: $border;
		border-right: $border;

		.toolbar {
			height: 100%;
		}

		&:empty {
			display: none;
		};
	}

	&__nav {
		grid-area: nav;

		display: flex;
		align-items: center;
	}

	&__hamburger-button {
		background: none;
		border: none;
		position: relative;
		width: space.size(md) * 3;
		height: 100%;

		color: palette.$text;
		cursor: pointer;

		.fa-times {
			opacity: 0;
		}

		&--open {
			.fa-times {
				opacity: 1;
			}

			.fa-bars {
				opacity: 0;
			}
		}

		i {
			position: absolute;
			transform: translate(-50%, -50%);

			transition: opacity 250ms;

			pointer-events: none;
		}
	}
}

.page-header-menu {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	
	display: flex;
	flex-direction: column;
	align-items: stretch;

	background-color: palette.$bg;
	opacity: 0;
	
	z-index: 1;

	transition: all 250ms;
	visibility: hidden;

	&--open {
		visibility: unset;
		transform: translateY(100%);
		opacity: 1;
	}
	
	.nav-item {
		display: flex;
		height: var(--page-header-height);

		span {
			margin-left: auto;
		}
	}
}
