$shadow-xs: 0 1px 1px rgba(black, .3);
$shadow-sm: 0 2px 2px rgba(black, .5);
$shadow-lg: 0 5px 5px rgba(black, .2);

:root {
	--shadow-sm: $shadow-sm;
	--shadow-lg: $shadow-lg;
}

.shadow-sm {
	box-shadow: $shadow-sm;
}

.shadow-lg {
	box-shadow: $shadow-lg;
}
