@use 'sass:map';
@use '../palette';

$text-sizes: (
	sm: .85rem,
	md: 1rem,
	lg: 1.2rem,
	xl: 1.5rem,
);

@mixin text-size($key) {
	font-size: map.get($text-sizes, $key);
}

.text-sm {
	@include text-size(sm)
}
.text-md {
	@include text-size(md)
}
.text-lg {
	@include text-size(lg)
}
.text-xl {
	@include text-size(xl)
}
.text-right {
	text-align: right;
}
.text-bolder {
	font-weight: bolder;
}
.text-italic {
	font-style: italic;
}
.text-over-image {
	filter: drop-shadow(0 0 .1em black);
}
.text-center {
	text-align: center;
}

.copy {
	display: flex;
	flex-direction: column;
	
	* {
		margin: 0;
	}

	& > * + * {
		margin-top: var(--copy-spacing, 1em);
	}

	&--sm {
		--copy-spacing: .5em;
	}

	@for $i from 1 through 6 {
		& > h#{$i} + p {
			margin-top: .3em
		}
	}

	code {
		padding: 0 .5ch;
		border-radius: 5px;
		background-color: #3B5B6D;
		font-weight: bolder;
	}
}