@use '../../styles/space';
@use '../../styles/palette';

.issues-layout {
	position: relative;
	display: grid;
	grid-template-areas:
		'description map'
		'issues      map'
	;
	grid-template-columns: 420px 1fr;
	grid-template-rows: auto 1fr;
	height: 100%;
	max-height: 100%;

	&__description {
		grid-area: description;

		padding: space.size(sm) space.size(md);
		background-color: palette.$fg;
		border-bottom: 1px solid palette.$page;
	}

	&__issues {
		grid-area: issues;
		position: absolute;

		width: 100%;
		height: 100%;
		max-height: 100%;

		overflow-y: scroll;

		padding: space.size(md);
		padding-top: 0;
	}

	&__map {
		grid-area: map;
	}
}