@use '../../../styles/space';
@use '../../../styles/palette';
@use '../../../styles/breakpoint';


.snmp-probe-list {
	display: grid;
	grid-template-columns: 100%;
	gap: space.size(md);

	padding: space.size(md);
	margin: 0;
	margin-bottom: auto;

	list-style: none;

	@include breakpoint.up(sm) {
		grid-template-columns: repeat(auto-fit, 400px);
	}
}