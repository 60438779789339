@use '../../../../styles/space';


.wireless-range-units-radio {
	&__inputs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		input {
			margin-right: space.size(sm);
		}
		
		label {
			margin-right: space.size(md);
		}
	}
}