@use 'sass:color';
@use '../../../styles/space';
@use '../../../styles/palette';
@use '../../../styles/shape';
@use '../../../styles/shadow';

.notification-wrapper {
	position: absolute;
	bottom: 0;
	right: 0;

	padding: space.size(sm);
	padding-top: 0;

	width: 50ch;
	max-width: 100vw;

	transition: all 300ms;
	transform: translate(100%);
	opacity: 0;
}

.notification {
	display: flex;
	gap: space.size(md);
	align-items: center;
	
	border-radius: shape.$border-radius;
	border: 2px solid var(--notification-fg, palette.$text);
	
	background-color: var(--notification-bg, palette.$page);
	
	box-shadow: shadow.$shadow-lg;
	padding: space.size(md);

	&--info {
		--notification-fg: #{palette.$brand};
	}
	&--success {
		--notification-fg: #{palette.$good};
	}
	&--warning {
		--notification-bg: #{palette.$hl-warning};
	}
	&--error {
		--notification-bg: #{palette.$hl-danger};
	}

	&__icon {
		font-size: 1.5rem;
		color: var(--notification-fg);
	}

	&__message {
		flex-grow: 1;
	}
}