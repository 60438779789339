@use '../../styles/space';
@use '../../styles/palette';
@use '../../styles/shape';

.aside {
	display: grid;
	column-gap: space.size(xs);
	grid-template-columns: auto 1fr;

	background: palette.$hl;
	color: palette.$text-hl;
	padding: space.size(xs);
	border-radius: shape.$border-radius;

	&__title {
		place-self: center start;
	}
}
