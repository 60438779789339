@use '../../styles/space';
@use '../../styles/palette';

.definition-list {	
	margin: 0;

	&__item {
		display: grid;
		grid-template-areas: 
			'label copy'
			'value copy'
		;
		grid-template-columns: 1fr auto;
		gap: .1rem space.size(sm);

		td {
			grid-area: label;
			font-weight: bolder;
			font-size: .9rem;
		}
	
		dd {
			grid-area: value;
			margin: 0 !important;
			display: grid;
			grid-auto-flow: row;

			color: palette.$text-hl;
			
			&.mono {
				font-family: BearingMono;
			}
		}
	}
}