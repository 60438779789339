@use './material-design';
@use '../../../styles/palette';

.map-text-field {
	display: flex;
	align-items: center;
	
	@include material-design.base();
	@include material-design.widget();
	padding-block: 0;
	padding-inline-end: 0;

	cursor: text;

	&:focus-within {
		outline: 2px solid palette.$brand;
	}

	input {
		font: inherit;
		
		padding-inline-start: material-design.$spacing;
		background-color: transparent;
		box-shadow: none;
		outline: none;
		border: none;
		height: 100%;
	}
}