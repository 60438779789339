@use './material-design';

.map-button {
	@include material-design.base();
	display: flex;
	align-items: center;
	height: 40px;
	padding-block: 0;

	cursor: pointer;
	&:hover {
		background-color: #ebebeb;
	}
	
	> * + * {
		margin-inline-start: calc(material-design.$spacing / 2);
	}
}