@use '../../../styles/space';
@use '../../../styles/z';


.notification-area {
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	z-index: z.$notifications-z-index;
}