@use 'palette';
@use 'breakpoint';

html, body {
    border-color: palette.$border;
    background-color: palette.$page;
    color: palette.$text;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

body {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: min-content auto min-content;
}

h1 {
    margin: 0;
    padding: 6px 10px;
}

h2.loading {
    text-align: center;
}

a {
    color: palette.$text;
	text-decoration: underline #999999;
    &:hover {
        color: palette.$text-hl;
		text-decoration: underline #999999;
    }
}

#container > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;

    > section {
        max-width: 400px;
        min-width: 300px;
        width: 100%;
    }
}

svg text {
    fill: palette.$text;
}

@media (max-width: breakpoint.$sm) {
    #container > div > section {
        max-width: 100vw;
    }
}

.visually-hidden {
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}
