@use '../../styles/space';
@use '../../styles/breakpoint';

.toolbar {
	display: grid;
	grid-template-areas:
		'content   more-button'
		'more-menu more-menu'
	;

	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr 0;

	&__content {
		grid-area: content;
	}
	&__more-button {
		grid-area: more-button;
	}
	&__more-menu {
		grid-area: more-menu;
		overflow: visible;
		z-index: 1;
	}

	select {
		font-size: 1.2rem;
		overflow: hidden;
	}
}
