@use '../../../../styles/space';
@use '../../../../styles/shadow';
@use '../../../../styles/palette';


.inventory-search-view {
	position: relative;
	display: grid;
	grid-template-areas: 'filter' 'results';
	grid-template-rows: auto 1fr;
	height: var(--page-main-height);

	&__filter {
		grid-area: filter;
		padding: space.size(md);

		box-shadow: shadow.$shadow-sm;
		background-color: palette.$fg;
		z-index: 1;
	}

	&__results {
		grid-area: results;
		height: 100%;

		> * {
			padding: space.size(md);
		}
	}
}