@use '../styles/shadow';

.viewport {
	position: relative;
	height: 100%;
	width: 100%;
	
	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	&--shadow {
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			box-shadow: shadow.$shadow-sm inset;
			pointer-events: none;
		}
	}
}