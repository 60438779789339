@use '../../styles/palette';
@use '../../styles/space';


.copyable {
	display: inline-flex;
	align-items: baseline;
	font: inherit;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	color: inherit;
	cursor: pointer;
	transition: background 150ms;
	border-radius: space.size(xs);

	.icon {
		margin-inline-start: .25ch;
		margin-block: auto;
		opacity: .6;
		
		transition: opacity 150ms, color 150ms;
	}

	&:hover {
		background: #fff1;
		.icon {
			opacity: 1;
		}
	}

	&.copied {
		background: none;
		cursor: default;
		.icon {
			color: palette.$text-good;
			opacity: 1;
		}
	}
}