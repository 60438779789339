.chevron {
	transform: rotate(0);
	transition: transform 400ms;	
	
	&--down {
		transform:  rotate(180deg);
	}

	&--md {
		max-width: 18px;
	}

	&--sm {
		max-width: 12px;
	}

	&--xs {
		max-width: 9px;
	}
}