@use '../../../styles/space';
@use '../../../styles/palette';


.map-route-search-results {
	display: flex;
	flex-direction: column;

	max-height: 60vh;
	overflow-y: auto;
	margin-top: 2px;
	
	background-color: white;
	color: black;
	font: inherit;
	font-size: 1rem;
	
	&__message {
		color: palette.$fg;
		padding: space.size(md);
	}
	
	.map-route-search-result {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: space.size(sm);
		padding: space.size(sm);

		pointer-events: fill;
		cursor: pointer;

		&[data-selected] {
			background-color: palette.$brand;
			color: white;
		}
		
		* {
			user-select: none;
			pointer-events: none;
		}

		&__icon {
			margin-block: auto;
			opacity: .7;
		}

		&__name {
			margin-block: auto;
		}

		&__fields {
			margin: 0;
			margin-block-start: space.size(xs);
			padding: 0;
			list-style: none;
			grid-column: 2/-1;
			font-size: .9rem;
		}
	}
}