@use '../../styles/palette';


.deviceStatus {
	display: inline-flex;
	align-items: center;

	&--Good {
		color: palette.$text-good;
	}

	&--Warning {
		color: palette.$text-warning;
	}

	&--Down {
		color: palette.$text-danger
	}
}