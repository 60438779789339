@use '../../../../styles/breakpoint';
@use '../../../../styles/space';


.site-details-header {
	display: grid;
	grid-template-columns: 1fr auto;

	h2 {
		display: none;
		margin: 0;

		@include breakpoint.up(md) {
			display: flex;
			align-items: center;
			gap: space.size(sm);
		}
	}

	nav {
		display: flex;
		gap: space.size(md);
	}
}
