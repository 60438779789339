@use '../../styles/space';

.csv-validation-error-view {
	border: 2px solid var(--color-fg-danger);
	border-radius: space.size(sm);
	font-size: 1rem;

	.alert {
		border-radius: 0;
	}

	.invalidFormat {
		display: flex;
		flex-direction: column;
		> * + * {
			margin-block-start: space.size(sm);
		}

		.comparison {
			max-width: min(600px, 100vw);
			display: flex;
			flex-direction: column;
			background: var(--color-text);
			color: var(--color-bg);
			padding: space.size(md);
			border-radius: space.size(sm);
			overflow-x: auto;
			width: 100%;
		}
	}

	.invalidRows {
		table {
			border-spacing: 0;
		}

		thead > td {
			font-weight: bolder;
			border-block-end: 1px solid var(--color-hl);
		}
		&, td {
			padding: space.size(sm);
		}

		tr {
			&:nth-child(even) {
				background: var(--color-bg);
			}
		}
		
		tbody {
			td {
				&:first-child {
					vertical-align: top;
					font-weight: bolder;
					font-size: 1.1rem;

					border-inline-end: 1px solid var(--color-hl);
				}

				ul {
					margin: 0;
					padding: 0 space.size(md);

					.invalidColumn {
						font-weight: bolder;
					}
					.invalidValue {
						.missingValue {
							font-style: italic;
						}
						color: var(--color-text-danger);
					}

					ul {
						padding-block: space.size(xs);
					}
				}
			}
		}
	}
}