@use '../../styles/space';
@use '../../styles/palette';


.menu-btn {
	display: flex;
	justify-content: flex-start !important;
	align-items: center !important;
	
	gap: space.size(sm);
	padding-right: 4px !important;

	transition: border-radius 150ms;

	& > i + * {
		margin-left: unset !important;
	}

	.chevron {
		margin-left: auto;
	}

	&--open {
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		background-color: palette.$text-hl !important;
	}
}