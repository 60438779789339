@use '../../../styles/palette';

.modal {
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	max-width: unset;
	max-height: unset;
	margin: 0;
	padding: 0;
	border: none;

	background: none;

	backdrop-filter: blur(0);
	transition: backdrop-filter var(--modal-transition-duration);
	
	color: palette.$text;

	&::backdrop {
		display: none;
	}
	
	&__backdrop, &__content {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	
	&__backdrop {
		background-color: black;
		opacity: 0;
		transition: opacity var(--modal-transition-duration);
		pointer-events: fill;
	}
	
	&--visible {
		backdrop-filter: blur(2px);

		.modal__backdrop {
			opacity: .4;
		}
	}
}