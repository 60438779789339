@use '../../../styles/space';

.icon {
	width: var(--icon-size);
	height: var(--icon-size);
	
	pointer-events: none;
	
	&--xs {
		--icon-size: #{space.size(sm)};
	}
	
	&--sm {
		--icon-size: 1rem;
	}
	
	&--md {
		--icon-size: 1.5rem;
	}

	&--lg {
		--icon-size: 2rem;
	}

	&--xl {
		--icon-size: 3rem;
	}
}

.icon--powercode {
	margin-block-end: 1%;
	a & {
		opacity: .8;
	}
	a:hover & {
		opacity: 1;
	}
}
