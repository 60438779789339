@use '../../../styles/breakpoint';
@use '../../../styles/space';


.sites-toolbar {
	display: grid;
	grid-template-areas:
		'site'
		'options'
	;
	padding: space.size(sm);
	gap: space.size(sm);

	&__site-select {
		grid-area: site;
		max-width: 25ch;
	}

	&__device-select {
		max-width: 20ch;
	}
	
	&__options {
		grid-area: options;
		display: flex;
		gap: space.size(sm);
		margin-left: auto;

		> * {
			white-space: nowrap;
		}
	}

	@include breakpoint.up(sm) {
		grid-template-areas: 'site options';
		grid-template-columns: auto 1fr;
	}
}
