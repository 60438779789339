@use '../../../styles/space';


.analysis-issues-toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: space.size(sm);
	padding: space.size(sm);
}