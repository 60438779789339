@use '../../../styles/space';
@use '../../../styles/palette';


.analysis-issues-table {
	display: flex;
	flex-direction: column;

	h2 {
		flex: 1;
		margin: 0;
		font-size: 1.3rem;
	}

	h3 {
		margin: 0 0 space.size(sm) 0;
		font-size: 1.1rem;
	}

	section header {
		display: flex;
		align-items: center;
		gap: space.size(sm);

		img {
			transform: translateY(-5px); // Align with text.
		}
	}
}