@use './material-design';

.map-select {
	position: relative;
	max-width: min-content;
	
	&.disabled {
		> * {
			color: material-design.$disabled-color;
		}
	}
	
	select {
		@include material-design.base();
		@include material-design.widget();
		padding-inline-end: calc(material-design.$spacing * 2.5);
		padding-block: 0;
		
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	
	&__arrow {
		position: absolute;
		top: 50%; right: material-design.$spacing;
		transform: translateY(-50%);

		color: black;
		pointer-events: none;
	}
}
