@use '../../styles/space';
@use '../../styles/palette';


.details {
	summary {
		display: grid;
		grid-template-columns: 1fr 18px;
		gap: space.size(sm);
		padding: space.size(sm) 0;

		&, & * {
			cursor: pointer;
		}
	}

	&__control {
		display: flex;
		align-items: center;
	}
}