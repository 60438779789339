@use '../../../../../styles/breakpoint';
@use '../../../../../styles/space';


.inventory-details-device {
	display: grid;
	grid-template-columns: 1fr;

	row-gap: space.size(md);

	@include breakpoint.up('sm') {
		--numColumns: 2;
		--numRows: 2;
		grid-template-columns: repeat(var(--numColumns), auto) 1fr;
		grid-template-rows: repeat(calc(var(--numRows) - 1), auto) 1fr;
		grid-template-areas:
			'overview          snmp .'
			'radios            snmp .'
			'subscriberModules snmp .'
		;

		margin-inline-end: auto;
		margin-block-end: auto;

		> * {
			max-width: 350px;
		}

		&__overview {
			grid-area: overview;
		}
		&__radios {
			grid-area: radios;
		}

		&__subscriberModules {
			grid-area: subscriberModules;
		}

		&__snmp {
			grid-area: snmp;

			margin-inline-start: space.size(md);
		}
	}
	
	@include breakpoint.up('md') {
		--numColumns: 3;
		--numRows: 2;
		grid-template-areas:
			'overview subscriberModules snmp .'
			'radios   subscriberModules snmp .'
		;

		&__subscriberModules {
			margin-inline-start: space.size(md);
		}
	}
}