@use '../../styles/palette';
@use '../../styles/space';
@use '../../styles/shadow';


.sidebar-template {
	position: relative;
	display: grid;
	grid-template-areas: 
		'side sidebar-control'
		'side main'
	;
	grid-template-columns: 0 1fr;
	grid-template-rows: auto 1fr;
	
	height: 100%;

	&--large {
		grid-template-columns: 350px 1fr;
		max-height: var(--page-main-height);
		
		.sidebar-template__main {
			overflow-y: auto;
		}
	}

	&__side {
		grid-area: side;
		background-color: palette.$bg;
	}

	&__main {
		grid-area: main;
	}

	&__show-sidebar {
		position: sticky;
		top: 56px;
		grid-area: sidebar-control;
		display: grid;

		padding: space.size(sm);
		background-color: palette.$bg;

		box-shadow: shadow.$shadow-sm;
	}

	&__drawer {
		width: 100%;
		background-color: palette.$bg;
	}
}