.site-image {
	--fade-fast: 400ms;
	--fade-slow: 600ms;

	svg {
		width: 100%;
		height: auto;
	}

	:root {
		--fade-fast: 400ms;
		--fade-slow: 600ms;
	}
	
	.eq-text {
		opacity: 0;
		pointer-events: none;
		transition: opacity var(--fade-slow);
	}
	
	circle {
		transition: fill var(--fade-slow), opacity var(--fade-slow);
	}

	.eq-filter-ap {
		.eqType--ap {
		  visibility: hidden; 
		}
	}
	.eq-filter-bh {
		.eqType--bh {
			visibility: hidden; 
		}
	}
	.eq-filter-sm {
		.eqType--sm {
			visibility: hidden; 
		}
	}
}
