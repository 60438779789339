@use '../../../../../styles/palette';
@use '../../../../../styles/space';
@use '../../../../../styles/shape';


.device-snmp-details {
	display: flex;
	flex-direction: column;

	> * + * {
		margin-block-start: space.size(sm);
	}
	
	h4 {
		margin: 0 0 space.size(xs) 0;
	}
	
	&__status {
		display: flex;
		align-items: center;
			
		padding: space.size(xs) space.size(sm);
		border-radius: shape.$border-radius;

		min-width: 300px;
		
		color: palette.$text-hl;
		
		> * + * {
			margin-inline-start: space.size(sm);
		}
		
		&.SUCCESS {
			background-color: palette.$good;
			color: palette.$page;
		}

		&.PARTIAL {
			background-color: palette.$fg-warning;
		}

		&.FAILURE {
			background-color: palette.$fg-danger;
		}

		&.WAITING {
			background-color: palette.$fg;
		}

		&.UNIDENTIFIED, .NOT_IDENTIFIED {
			background-color: palette.$text;
			color: palette.$fg;
			border: 1px solid palette.$fg;
		}
	}

	&__values {
		display: flex;
		flex-direction: column;

		pre {
			font-family: BearingMono;
			font-size: inherit;
		}
	}
}