@use 'breakpoint';

.ap-spectrum {
    margin-bottom: 10px;
}

.ap-image {
    height: 400px;
    margin-bottom: 15px;

    svg {
        width: 100%;
        height: auto;
    }
}

.ap-data {
    min-height: 100px;
}

@media (max-width: breakpoint.$sm) {
    .ap-image {
        height: auto;
    }
}