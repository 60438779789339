@use '../../../styles/space';
@use '../../../styles/shadow';
@use '../../../styles/breakpoint';

.usersAdmin {
	.toolbar {
		display: flex;
		justify-content: flex-end;

		> * + * {
			margin-inline-start: space.size(sm);
		}
	}
	ul {
		padding: 0;
	}

	.userItem {
		display: flex;
		flex-direction: column;
		gap: space.size(sm);

		.avatar {
			position: relative;
			width: 64px;
			height: 64px;
			background: var(--color-hl);
			border-radius: 50%;
			overflow: hidden;
			box-shadow: shadow.$shadow-sm;

			img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		button {
			justify-content: flex-start;
			width: 100%;
			padding-inline: 0;
			margin-inline-end: auto;
		}

		@include breakpoint.up(md) {
			flex-direction: row;
			justify-content: space-between;

			button {
				flex-direction: row-reverse;
				padding: 0;
				margin-inline-start: auto;
				svg {
					margin: 0;
					margin-inline-start: space.size(sm);
				}
			}
		}
	}
}