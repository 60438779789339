@use '../../../styles/typography/typography';
@use '../../../styles/palette';


.snmp-probe-filter-list {
	display: grid;
	grid-auto-flow: row;
}

.snmp-probe-filter-list-item {
	display: grid;

	button {
		border: 2px solid transparent;
		color: inherit;
		font-size: inherit;
		text-align: start;
		cursor: pointer;

		&[aria-selected=true] {
			border-color: palette.$brand;
		}
	}

	&__key {
		font-weight: bolder;
	}

	&__type {
		@include typography.text-size(sm);
	}
}