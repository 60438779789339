#login {
    padding: 10px;

    form {
        font-size: 1.3em;
        margin: auto;
        max-width: 400px;

        input {
            font-size: 1em;
            width: 100%
        }

        input.login-btn {
            display: block;
            margin-left: auto;
            width: 50%;
        }

        > div {
            margin: 10px;
        }
    }
}