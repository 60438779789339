@use '../../../../styles/space';
@use '../../../../styles/palette';
@use '../../../../styles/shape';


.map-route-focus-tools {
	display: flex;
	align-items: stretch;

	> * + * {
		margin-inline-start: space.size(sm);
	}

	&__allSites {
		margin-block: auto;
	}

	.map-route-focus-item {
		display: grid;
		grid-template-columns: 1fr auto; 

		border-radius: shape.$border-radius;

		background-color: palette.$fg;
		&--hl{
			background-color: palette.$hl;
		}

		button {
			display: flex;
			align-items: center;
			border: none;
			
			padding: 0;
			color: palette.$text;
			font: inherit;
			font-size: .9rem;
			background-color: transparent;
			padding-inline-start: space.size(sm);

			cursor: pointer;
			&:disabled {
				cursor: unset;
				color: palette.$text-hl
			}

			> * + * {
				margin-inline-start: space.size(sm);
			}

			&:last-child {
				padding-inline-end: space.size(sm)
			}
		}

		&__label {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: min(15ch, 25vw);
		}
	}
}

