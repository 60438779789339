@use '../../../styles/breakpoint';
@use '../../../styles/palette';
@use '../../../styles/shadow';

.license-flag {
	padding: 1px 5px;

	background: palette.$brand-hl;
	color: palette.$bg;
	margin-top: 3px;	
	transform: skewX(-10deg);
	box-shadow: shadow.$shadow-sm;

	border: none;
	
	font-family: inherit;
	font-weight: 900;
	font-size: .8rem;
}

button.license-flag {
	cursor: pointer;
}
