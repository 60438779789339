@use '../../../styles/space';
@use '../../../styles/palette';
@use '../../../styles/breakpoint';


.snmp-probe-view {
	display: flex;
	flex-direction: column;

	> * + * {
		margin-top: space.size(sm);
		padding-top: space.size(sm);
		border-top: 1px solid palette.$hl;
	}
	
	h2, h3 {
		margin: 0;
		font-size: 1.2rem;
	}

	h3 {
		margin-bottom: space.size(xs);
		font-size: 1rem;
	}

	&__property-list {
		display: flex;
		flex-direction: column;
	}

	&__property {
		display: grid;
		grid-template-areas:
			'label copy'
			'value copy'
		;
		grid-template-columns: 1fr 44px;
		gap: space.size(xs);

		dt {
			grid-area: label;
		}
		
		dd {
			grid-area: value;
			margin-left: 0;
		}

		button {
			grid-area: copy;
			width: 44px;
			height: 44px;
		}

		&--copyable {
			padding-right: 0;
		}
	}

	&__sql {
		display: grid;
	}

	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: space.size(xs);
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.oid {
		font-size: .9rem;

		@include breakpoint.up(sm)  {
			font-size: unset;
		}
	}
}