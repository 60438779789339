@use '../styles/palette';
@use '../styles/space';
@use '../styles/shape';
@use '../styles/shadow';
@use '../styles/typography/typography';


.chip {
	background-color: palette.$hl;
	color: palette.$text;
	border: 2px solid palette.$bg;
	border-radius: space.size(md);
	font-size: .8rem;
	padding: space.size(xs) space.size(sm);

	transition: border-color 150ms, color 150ms, box-shadow 150ms;
	
	&--highlight {
		border-color: palette.$brand;
		color: palette.$text-hl;
		box-shadow: shadow.$shadow-sm;
		
	}

	&--interactive {
		cursor: pointer;

		&:hover {
			border-color: palette.$text;
		}
	}
}