$spacing: 12px;
$widget-height: 40px;
$disabled-color: rgba(0, 0, 0, 0.5);

@mixin base {
	@include typography();
	@include shadow();
	@include background();
	
	border-radius: 0;
	border: none;
	padding: $spacing;
}

@mixin widget {
	height: $widget-height;
}

@mixin typography {
	font-family: Roboto, Arial, sans-serif;
	font-size: 18px;
	color: black;
}

@mixin shadow {
	box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
}

@mixin background {
	background: rgb(255, 255, 255);
}

@mixin hoverable {
	color: rgb(86, 86, 86);

	&:focus, &:hover {
		color: black;
	}
}
