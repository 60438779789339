@use '../../styles/palette';

.drawer {
	position: fixed;
	height: 100%;
	max-height: 100vh;
	overflow-y: auto;

	width: fit-content;

	&--left {
		left: 0;
		--drawer-closed-translate: -100%;
	}

	&--right {
		right: 0;
		--drawer-closed-translate: 100%;
	}

	transform: translateX(var(--drawer-closed-translate));
	transition: transform var(--modal-transition-duration);
}

.modal--visible {
	.drawer {
		transform: translateX(0);
	}
}