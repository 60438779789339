@use '../../../../styles/space';
@use '../../../../styles/palette';
@use '../../../../styles/shadow';

.map-route-tool-menu {
	width: 100vw;
	max-height: 0px;
	overflow-y: visible;

	&__content {
		position: relative;
		z-index: 0;
		display: flex;
		flex-direction: column;

		> * + * {
			margin-block-start: space.size(sm);
		}

		background-color: palette.$page;
		box-shadow: shadow.$shadow-lg;
		padding: space.size(md);
		padding-block-start: space.size(sm);
	}

	&__search {
		z-index: 1;
	}
}