#map {
    top: 48px;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute !important;

    h2 {
        margin: 10px;
    }

    #frequencyChoice {
        margin: 10px;

    }
    
    ~ footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}