@use '../../../styles/palette';
@use '../../../styles/space';
@use '../../../styles/shadow';
@use '../../../styles/breakpoint';

.snmp-probe-filter-view {
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;

	&__query {
		display: flex;
		align-items: center;
		padding: space.size(md);
		background-color: palette.$fg;

		padding-left: space.size(sm);
		@include breakpoint.up(sm) {
			padding-left: space.size(md);
		}
		
		input {
			flex: 1;
		}
		
		> * + * {
			margin-left: space.size(sm);
		}
	}

	&__list {
		display: grid;
		grid-auto-flow: row;
		
		list-style: none;
		padding: 0;
		margin: space.size(md);
		
		> * + * {
			margin-top: space.size(sm);
		}
	}
}