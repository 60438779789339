@use './material-design';

.map-slider {
	display: flex;
	align-items: center;
	
	@include material-design.base();
	@include material-design.widget();

	> * + * {
		margin-inline-start: calc(material-design.$spacing / 2);
	}

	input {
		width: 128px;
	}
}