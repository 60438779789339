@use '../../../styles/palette';
@use '../../../styles/shape';
@use '../../../styles/space';
@use '../spectrum-base';

.spectrum-graph {
	position: relative;
	width: 100%;
	z-index: 0;
	
	&__segments {
		display: flex;
		width: 100%;

		@include spectrum-base.style();

		&--with-popup {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.spectrum-graph-segment {
		flex-grow: var(--seg-width);
		height: 100%;

		transition: opacity 150ms, filter 150ms;
		
		&--excluded {
			background-color: white;
			flex-grow: 0;
			width: 2px;
		}

		&--overlap {
			background-color: palette.$hl-danger;
		}

		&--highlight {
			outline: 1px solid white;
			outline-offset: -1px;
			z-index: 2;
		}

		&:first-child {
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
		}

		&:last-child {
			border-top-right-radius: inherit;
			border-bottom-right-radius: inherit;
		}

		&--filtered {
			opacity: .4;
			filter: saturate(.8) brightness(.5);
		}
	}
}

.spectrum-graph-popup {
	display: flex;
	flex-direction: column;
	
	border: 2px solid palette.$hl;
	border-top: none;

	border-bottom-left-radius: shape.$border-radius;
	border-bottom-right-radius: shape.$border-radius;

	background-color: palette.$bg;

	&__freq, &__info {
		padding: space.size(sm);
	}
	
	&__info {
		display: flex;
		justify-content: space-between;
		
		border-top: 1px solid palette.$hl;
	}
}