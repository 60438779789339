@use '../space';
@use '../shape';
@use '../palette';


.panel {
	padding: space.size(sm);
	background-color: palette.$fg;
	border-radius: shape.$border-radius;
	--panel-border-color: transparent;

	border: 0 solid var(--panel-border-color);
	border-left-width: 2px;
	border-right-width: 2px;

	overflow: hidden;
	
	&--info {
		--panel-border-color: #{palette.$brand};
	}

	&--dark {
		background-color: palette.$bg;
	}

	&--danger {
		background-color: palette.$fg-danger;
	}

	&--warning {
		background-color: palette.$fg-warning;
	}
}

.panel-group {
	display: grid;
	grid-template-columns: 1fr;
	gap: space.size(xs);

	> .panel {
		border-radius: 0;
		
		* + & {
			margin-top: unset;
		}
		
		&:first-child {
			border-radius: shape.$border-radius shape.$border-radius 0 0;
		}
		&:last-child {
			border-radius: 0 0 shape.$border-radius shape.$border-radius;
		}
		&:only-child {
			border-radius: shape.$border-radius;
		}
	}
}