@use '../../../../styles/space';

.map-route-toolbar {
	display: flex;
	padding: space.size(sm);
	min-height: 52px;

	> * {
		height: 100%;

		& + * {
			margin-inline-start: space.size(xs);
		}
	}

	&__focus {
		margin-inline-end: auto;
	}
}