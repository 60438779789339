.flex {
	display: flex !important;
}

.flex-justify-start {
	display: flex !important;
	justify-content: start !important;
}

.flex-justify-center {
	display: flex !important;
	justify-content: center !important;
}

.flex-justify-between {
	display: flex !important;
	justify-content: space-between !important;
}

.flex-justify-around {
	display: flex !important;
	justify-content: space-around !important;
}

.flex-justify-evenly {
	display: flex !important;
	justify-content: space-evenly !important;
}

.flex-justify-stretch {
	display: flex !important;
	justify-content: stretch !important;
}

.flex-justify-end {
	display: flex !important;
	justify-content: end !important;
}

.flex-align-center {
	display: flex !important;
	align-items: center !important;
}

.flex-align-start {
	display: flex !important;
	align-items: flex-start !important;
}

.flex-align-end {
	display: flex !important;
	align-items: flex-end !important;
}

.flex-center {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.flex-column {
	display: flex !important;
	flex-direction: column !important;
}

.flex-row {
	display: flex !important;
	flex-direction: row !important;
}

.flex-row-reverse {
	display: flex !important;
	flex-direction: row-reverse !important;
}

.flex-wrap {
	display: flex !important;
	flex-wrap: wrap;
}

.flex-1 {
	flex: 1;
}