@use '../../../../../styles/space';
@use '../../../../../styles/shape';
@use '../../../../../styles/breakpoint';


.inventory-details-site {
	display: grid;
	gap: space.size(md);
	height: 100%;
	max-height: 100%;

	@include breakpoint.up('sm') {
		grid-template-columns: minmax(auto, 350px) 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			'overview map'
			'devices  map'
		;

		&__overview {
			grid-area: overview;
		}

		&__devices {
			grid-area: devices;
			margin-block-end: auto;
			overflow-y: auto;
		}

		&__map {
			grid-area: map;
			max-height: 80vh;	
			border-radius: shape.$border-radius;
		}
	}

	&__deviceItem {
		display: flex;
		flex-direction: column;

		&Name + * {
			margin-block-start: space.size(sm) !important;
		}
	}
}