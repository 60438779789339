@use '../../../../styles/palette';
@use '../../../../styles/breakpoint';
@use '../../../../styles/space';


.powercode-custom-fields-view {
	&.form {
		gap: 0;
	}
	
	// Account for fixed position submit button on mobile.
	padding-bottom: calc(var(--admin-pad-y) * 3);
	@include breakpoint.up(sm) {
		padding-bottom: 0;
	}
	
	&__submit {
		// On mobile, fix submit button to the bottom of the screen.
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: space.size(md);
		
		// At larger sizes, sticky the submit button to the bottom of scroll box.
		@include breakpoint.up(sm) {
			position: sticky;
			bottom: 0;
			width: unset;
			margin-top: calc(var(--admin-pad-y) * -.5);
			padding: space.size(md) 0 var(--admin-pad-y) 0;
			transform: translateY(var(--admin-pad-y));
		}

		background-color: palette.$page;

		::before {
			$grad-height: 12px;
			content: '';
			background: transparent;
			background: linear-gradient(#22222200, palette.$page);
			position: absolute;
			top: -$grad-height;
			left: 0;
			height: $grad-height;
			width: 100%;

			pointer-events: none;
		}
	}
}