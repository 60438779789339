@use '../../styles/palette';
@use '../../styles/shape';

@mixin style() {
	height: 44px;

	background-color: palette.$bg;
	border: 2px solid palette.$hl;
	border-radius: shape.$border-radius;
	overflow: hidden;
}