@use '../../../styles/palette';
@use '../../../styles/space';
@use '../../../styles/shape';


@mixin icon-and-text() {
	display: grid;
	grid-template-columns: 28px 1fr;
	gap: space.size(sm);

	border-left: 2px solid transparent;

	i {
		margin: auto;
	}
}

.admin-tab-controls {
	display: flex;
	flex-direction: column;

	&__section {
		display: flex;
		flex-direction: column;
	}

	&__section-header {
		@include icon-and-text();

		border-bottom: 1px solid palette.$hl;
		margin: space.size(sm) 0;
		padding: space.size(xs) space.size(sm);
		
		h2 {
			margin: auto 0;
			color: palette.$text;
			opacity: .8;
			font-size: 1rem;
			font-weight: 600;
		}

		.powercode-icon {
			transform: translateX(2px);
		}
	}

	&__link {
		@include icon-and-text();
		align-items: center;

		padding: space.size(sm);


		border-radius: shape.$border-radius;
		
		font-size: 1.2rem;
		text-decoration: none;

		i {
			opacity: .8;
		}

		&:hover {
			background: rgba(#585858, .3);
		}

		&.active {
			background: palette.$hl;
			color: palette.$text-hl;
			border-color: palette.$brand;
		}
	}
}