@use '../../../../styles/palette';
@use '../../../../styles/shape';
@use '../../../../styles/space';

.next-step {
	width: 100%;
	padding: space.size(md);
	padding-top: 0;

	display: grid;
	grid-auto-flow: row;

	h3 {
		margin-top: 0;
	}
}