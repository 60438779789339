@use '../../styles/palette';
@use '../../styles/space';
@use '../../styles/shape';
@use '../../styles/shadow';
@use '../../styles/breakpoint';

.dialog {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	
	color: palette.$text;
	background-color: palette.$fg;

	width: 90vw;
	max-width: 90vw;

	@include breakpoint.up(sm) {
		width: fit-content;
	}

	&--narrow {
		width: 400px;
	}

	border-radius: shape.$border-radius;
	overflow: hidden;

	opacity: 0;
	transition: opacity var(--modal-transition-duration);
	
	box-shadow: shadow.$shadow-lg;
	
	> header, > footer {
		background-color: palette.$bg;
		padding: space.size(sm);
	}
	
	> header, > footer, > main {
		padding: space.size(sm);
	}

	> header {
		h2 {
			margin: 0;
		}

		.dialog-close-button {
			margin-right: -(space.size(sm));
		}
	}
}

.modal--visible .dialog {
	opacity: 1;
}