@use '../../../../styles/space';
@use '../../../../styles/palette';
@use '../../../../styles/breakpoint';


.inventory-focus-view {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: auto 1fr;
	gap: space.size(md);
	padding-block-end: space.size(md);
	height: 100%;

	> * {
		padding: 0 space.size(md);
	}

	&__backButton {
		margin-block-end: auto;
		display: flex;
		justify-content: flex-start;
	}
	
	&__header {
		background-color: palette.$bg;
		padding-block: space.size(sm);
		> * + * {
			margin-block-start: space.size(sm);
		}
	}

	&__title {
		display: flex;
		align-items: center;
		margin: 0;

		> * + * {
			margin-inline-start: space.size(sm);
		}
	}
	
	&__nav {
		display: flex;
		align-items: center;

		> * + * {
			margin-inline-start: space.size(md);
		}
	}

	.data-panel {
		display: grid;
		grid-auto-flow: row;
		gap: space.size(xs);

		a {
			overflow: hidden;  
		}
	}

	.data-label {
		font-weight: bolder;
		color: palette.$text;
	}

	.data-value {
		color: palette.$text-hl;
	}
}	