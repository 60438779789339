@use '../../styles/palette';
@use '../../styles/shadow';
@use '../../styles/shape';
@use '../../styles/space';


.alert {
	display: grid;
	grid-template-areas: 
		'icon title'
		'body body'
	;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	row-gap: space.size(sm);
	column-gap: var(--alert-icon-space, #{space.size(sm)});

	width: 100%;
	max-width: 90vw;
	margin: 0 auto;
	padding: 10px;
	border-radius: shape.$border-radius;
	
	
	background-color: var(--alert-background, #{palette.$fg});
	color: var(--alert-color, inherit);

	&__icon {
		grid-area: icon;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			color: var(--alert-icon-color, inherit)
		}
	}

	&__title {
		grid-area: title;
		display: flex;
		align-items: center;
		margin: 0;

		font-size: var(--alert-title-size, 1.5rem);
	}
	&__body {
		grid-area: body;
		margin-top: calc(var(--alert-icon-space) * 2);
		> p {
			margin: 0;

			& ~ p {
				margin-top: var(--alert-icon-space);
			}
		}
	}

	&--none {
		--alert-background: transparent;
		border: 1px solid palette.$hl;
	}

	&--info {
		--alert-icon-color: #{palette.$brand};
	}

	&--success {
		--alert-icon-color: #{palette.$good};
	}

	&--warning {
		--alert-background: #{palette.$fg-warning};
	}

	&--error {
		--alert-background: #{palette.$fg-danger};
	}

	&--md {
		--alert-title-size: 1.1rem;
		--alert-icon-space: #{space.size(xs)};
	}
}