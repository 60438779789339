@use '../../../styles/palette';
@use '../../../styles/shape';
@use '../spectrum-base';

.spectrum-slider {
	border-radius: shape.$border-radius;

	// Required to prevent Chrome from swallowing pointer move events.
	touch-action: none;

	&--dragging {
		cursor: grabbing;
	}
	
	&__graph {
		cursor: crosshair;

		.spectrum-graph-segment--used {
			cursor: grab;
		}
	}
}
