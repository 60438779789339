@use '../../../styles/space';

.sitesPageSection {
	display: flex;
	flex-direction: column;
	gap: space.size(sm);


	h2 {
		display: flex;
		align-items: center;
		gap: space.size(sm);
		margin: 0;
	}
}
