@use '../../../styles/palette';
@use '../../../styles/space';
@use '../../../styles/shape';
@use '../../../styles/shadow';


.error-dialog {
	width: 70ch;
	
	header {
		padding: 0;

		.alert {
			border-radius: 0;
		}
	}

	&__content {
		overflow-y: auto;
		max-height: 80vh;

		padding: space.size(sm);
	};

	&__details {
		max-height: 15em;
		overflow: auto;
	
		padding: space.size(sm);
		
		background-color: palette.$hl;
		color: palette.$text-hl;
	
		box-shadow: inset shadow.$shadow-sm;
		border-radius: shape.$border-radius;
	}

	&__footer {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		flex-wrap: wrap;
		gap: space.size(sm);

		> :first-child {
			margin-inline-start: auto;
		}
	}
}