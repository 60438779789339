@font-face {
	font-family: BearingDisplay;
	src: url('./typography/fonts/Michroma.woff');
}

@font-face {
	font-family: BearingBody;
	src: url('./typography/fonts/FiraSans-Regular.woff');
	font-weight: normal;
}

@font-face {
	font-family: BearingBody;
	src: url('./typography/fonts/FiraSans-Medium.woff');
	font-weight: bold;
}

@font-face {
	font-family: BearingMono;
	src: url('./typography/fonts/FiraCode-Regular.woff2');
}

@mixin font-body() {
	font-family: BearingBody, sans-serif;
}

@mixin font-display() {
	font-family: BearingDisplay, sans-serif;
}

@mixin font-mono() {
	font-family: BearingMono, monospace;
}

body {
	@include font-body();
}

h1 {
	@include font-display();
	font-weight: 500;
}

code {
	@include font-mono();
	font-size: inherit;
}

.font-display {
	@include font-display();
}

.font-body {
	@include font-body();
}

.font-mono {
	@include font-mono();
}
