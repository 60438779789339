@use '../../../../styles/space';
@use '../../../../styles/breakpoint';

.inventory-details-section {
	display: flex;
	flex-direction: column;

	// @include breakpoint.up('md') {
	// 	max-width: 350px;
	// }
	
	&__title {
		margin-block-start: 0;
		margin-block-end: space.size(xs);
	}

	&__content {

		border-left: 0;
		border-right: 0;

		ul {
			display: flex;
			flex-direction: column;
			
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}
}