@use '../styles/palette';
@use '../styles/space';


.issue-row {
	display: grid;
	grid-template-areas: 'content actions';
	grid-template-columns: 1fr auto;
	grid-template-rows: auto 1fr;
	gap: space.size(xs);

	&:not(.data-table__row--selected) {
		border: 2px solid transparent;
	}

	header {
		display: flex;
		justify-content: space-between;
		margin-bottom: space.size(sm);
		> * {
			margin-bottom: auto;
		}
	}

	&__content {
		grid-area: content;
	}
	
	&--squelched {
		&:not(.data-table__row--selected) {
			border: 2px solid palette.$fg-danger;
		}
		&, &:hover {
			background-color: palette.$page;
			color: #ddda;
		}
	}
}